import React from 'react';
import { Select, Label } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { Shape } from 'utils/createMarker';

interface ShapeSelectorProps {
  handleChange: (selectorValue: Shape) => void;
  value: Shape
}

export const ShapeSelector = ({ value, handleChange }: ShapeSelectorProps) => {
  const shapes: Record<string, Shape> = {
    square: Shape.Square,
    circle: Shape.Circle,
    marker: Shape.Marker,
    triangle: Shape.Triangle,
  };

  const options: Array<SelectableValue<string>> = [
    { label: 'Marker', value: 'marker' },
    { label: 'Square', value: 'square' },
    { label: 'Circle', value: 'circle' },
    { label: 'Triangle', value: 'triangle' },
  ];

  return (
    <>
      <Label>Marker Icon</Label>
      <Select
        key={value}
        options={options}
        value={Object.keys(shapes).find(k => shapes[k] === value)}
        onChange={(selectableValue) => {
          selectableValue.value !== undefined ? handleChange(shapes[selectableValue.value]) : null;
        }}
      />
    </>
  );
};
