import React from 'react';
import { Select, Label } from '@grafana/ui';
import { SelectableValue, StandardEditorContext } from '@grafana/data';

interface DataSelectorProps<T> {
  context: StandardEditorContext<T>;
  handleChange: (selectorValue: string) => void;
  value?: string;
}

export function DataSelector<T>({ value, handleChange, context }: DataSelectorProps<T>){
  const options: Array<SelectableValue<string>> = [];
  const frames = context.data;
  if (frames) {
    for (let i = 0; i < frames.length; i++) {
      options.push({
        label: frames[i].refId,
        value: frames[i].refId,
      });
    }
  }

  return (
    <>
      <Label>Data</Label>
      <Select
        key={value}
        options={options}
        value={value}
        onChange={(selectableValue) => {
          selectableValue.value !== undefined && handleChange(selectableValue.value);
        }}
      />
    </>
  );
};
