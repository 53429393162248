import React, { useState } from 'react';
import { LayerList } from './LayerList';
import { MapLayer } from 'types';
import { Input, Label, Select } from '@grafana/ui';
import { AddPopupRowButton } from './AddPopupRowButton';
import { ColumnSelector } from './ColumnSelector';
import { StandardEditorContext } from '@grafana/data';

export interface PopupEditorProps {
  layers: MapLayer[];
  layerId: number;
  handleChange: (newLayers: MapLayer[]) => void;
  context: StandardEditorContext<any, any>;
}

export function PopupEditor({ layers, layerId, handleChange, context }: PopupEditorProps) {
  const [selected, setSelected] = useState<number | null>(null);

  const layer = layers.find((l) => l.id === layerId);
  if (layer?.plotInfo === undefined) {
    return null;
  }
  const selectedRow = layer.popup.find((p) => p.id === selected);
  if (layer === undefined) {
    return null;
  }
  const popup = layer.popup;

  const availableDataframes = [];
  if (layer.plotInfo.layerCategory === 'marker' || layer.plotInfo.layerCategory === 'vector') {
    if (layer.plotInfo.dataframe === undefined) {
      return null;
    }
    if (selectedRow !== undefined) {
      selectedRow.dataframe = layer.plotInfo.dataframe;
    }
  } else if (layer.plotInfo.layerCategory === 'line') {
    if (layer.plotInfo.dataframe1 === undefined || layer.plotInfo.dataframe2 === undefined) {
      return null;
    }
    availableDataframes.push(layer.plotInfo.dataframe1);
    availableDataframes.push(layer.plotInfo.dataframe2);
  }

  const dataframe = context.data.find((df) => df.refId === selectedRow?.dataframe);

  const handleDeleteRow = (id: number) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = popup.filter((p) => p.id !== id);
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  };

  const handleNameChange = (id: number, newName: string) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = [...popup];
    const newRowId = newPopup.findIndex((r) => r.id === id);

    if (newRowId === -1) {
      return;
    }

    newPopup[newRowId].name = newName;
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  };

  const handleDataframeChange = (newDataframe: string) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = [...popup];
    const newRowId = newPopup.findIndex((r) => r.id === selected);

    if (newRowId === -1) {
      return;
    }

    newPopup[newRowId].dataframe = newDataframe;
    newPopup[newRowId].column = undefined;
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  };

  const handleToggleVisibility = (id: number) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = [...popup];
    const newRowId = newPopup.findIndex((r) => r.id === id);

    if (newRowId === -1) {
      return;
    }

    newPopup[newRowId].visible = !newPopup[newRowId].visible;
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  };

  const handleColumnChange = (selectedValue: string) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = [...popup];
    const newRowId = newPopup.findIndex((r) => r.id === selected);

    if (newRowId === -1) {
      return;
    }

    newPopup[newRowId].column = selectedValue;
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  };

  const handleUnitChange = (newUnits: string) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = [...popup];
    const newRowId = newPopup.findIndex((r) => r.id === selected);

    if (newRowId === -1) {
      return;
    }

    newPopup[newRowId].units = newUnits;
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  };

  const handleOrderChange = (oldIndex: number, newIndex: number) => {
    if (popup === undefined) {
      return;
    }
    const newPopup = [...popup];
    const currRow = {...newPopup[oldIndex]}
    newPopup.splice(oldIndex, 1)
    newPopup.splice(newIndex, 0, currRow)
    const newLayer = { ...layer };
    newLayer.popup = newPopup;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleChange(newLayers);
  }

  return (
    <>
      <Label>Popup Editor</Label>
      <AddPopupRowButton layerId={layerId} layers={layers} onChange={handleChange} />
      {popup !== undefined && (
        <LayerList
          layers={popup}
          selected={selected}
          handleSelect={setSelected}
          handleDeleteLayer={handleDeleteRow}
          handleNameChange={handleNameChange}
          handleToggleVisibility={handleToggleVisibility}
          handleOrderChange={handleOrderChange}
        />
      )}
      {availableDataframes.length > 1 && selectedRow !== undefined && (
        <>
          <Label>Dataframe</Label>
          <Select
            key={selected}
            value={selectedRow?.dataframe}
            options={availableDataframes.map((df) => {
              return { label: df, value: df };
            })}
            onChange={(selectableValue) =>
              selectableValue.value !== undefined && handleDataframeChange(selectableValue.value)
            }
          />
        </>
      )}

      {dataframe !== undefined && selectedRow !== undefined && (
        <>
          <Label>Column</Label>
          <ColumnSelector
            value={selectedRow.column}
            dataframe={dataframe}
            handleChange={(selectorValue) => {
              handleColumnChange(selectorValue);
            }}
          />
        </>
      )}
      {selectedRow !== undefined && (
        <>
          <Label>Units</Label>
          <Input
            value={selectedRow.units}
            onChange={(e) => {
              handleUnitChange((e.target as HTMLInputElement).value);
            }}
          />
        </>
      )}
    </>
  );
}
