import L from 'leaflet';
import { getArrowHTML, getEllipseHTML, getRectangleHTML } from './createVector';

export const markerHTML = `<?xml version="1.0" encoding="UTF-8"?>
            <svg viewBox="-4 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
                <title>map-marker</title>
                <desc>Created with Sketch.</desc>
                <defs>

            </defs>
                <g id="Vivid.JS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">
                        <g id="Icons" transform="translate(37.000000, 169.000000)">
                            <g id="map-marker" transform="translate(78.000000, 468.000000)">
                                <g transform="translate(10.000000, 6.000000)">
                                    <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="{color}">

            </path>
                                    <circle id="Oval" fill="#0C0058" fill-rule="nonzero" cx="14" cy="14" r="7">

            </circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>`;

const squareHTML = `<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg fill="{color}" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M 6 6 L 6 26 L 26 26 L 26 6 L 6 6 z"/></svg>`;

const circleHTML = `
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="{color}"/>
</svg>`;

const triangleHTML = `<svg fill="{color}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21,21H3L12,3Z"/></svg>`;

export function getColouredSVG(htmlString: string, color: string) {
  return htmlString.replace('{color}', color);
}

export function getSVG(htmlString: string, color: string, size: number) {
  return L.divIcon({
    html: htmlString.replace('{color}', color),
    iconSize: [size, size], // size of the icon
  });
}

// Keep this in sync please
export enum Shape {
  Marker,
  Circle,
  Triangle,
  Square,
}

export enum VectorShape {
  Arrow,
  Ellipse,
  Rectangle,
}

export function getIcon(icon: Shape, color: string, size: number) {
  switch (icon) {
    case Shape.Circle:
      return getSVG(circleHTML, color, size);
    case Shape.Square:
      return getSVG(squareHTML, color, size);
    case Shape.Triangle:
      return getSVG(triangleHTML, color, size);
    case Shape.Marker:
      return getSVG(markerHTML, color, size);
  }
}

export function getVectorIcon(icon: VectorShape, color: string, rotation: number, length: number, width: number) {
  let iconWidth = width >= 10 ? width : 10;
  switch (icon) {
    case VectorShape.Arrow:
      const arrowSvg = getArrowHTML(length, iconWidth, rotation);
      const arrowIcon = getSVG(arrowSvg, color, iconWidth);
      return arrowIcon;
    case VectorShape.Ellipse:
      const ellipseSvg = getEllipseHTML(length, iconWidth, rotation);
      const ellipseIcon = getSVG(ellipseSvg, color, iconWidth);
      return ellipseIcon;
    case VectorShape.Rectangle:
      const rectangleSvg = getRectangleHTML(length, iconWidth, rotation);
      const rectangleIcon = getSVG(rectangleSvg, color, iconWidth);
      return rectangleIcon;
    default:
      const defaultSvg = getEllipseHTML(length, iconWidth, rotation);
      const defaultIcon = getSVG(defaultSvg, color, iconWidth);
      return defaultIcon;
  }
}
