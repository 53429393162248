import { StandardEditorProps } from '@grafana/data';
import React, { useState } from 'react';
import { Graph } from 'types';
import { AddGraphButton } from './AddGraphButton';
import { LayerList } from './LayerList';
import { WindDirectionEditor } from './WindDirectionEditor';

export function GraphsEditor(props: StandardEditorProps<Graph[]>) {
  const [selected, setSelected] = useState<number | null>(null);
  const { value, onChange, context } = props;
  const handleNameChange = (id: number, newName: string) => {
    const newLayers = [...value];
    const i = newLayers.findIndex((l) => l.id === id);
    if (i === -1) {
      return;
    }
    const layer = { ...newLayers[i] };
    layer.name = newName;
    newLayers[i] = layer;
    onChange(newLayers);
  };

  const handleToggleVisibility = (id: number) => {
    const newLayers = [...value];
    const i = newLayers.findIndex((l) => l.id === id);
    if (i === -1) {
      return;
    }
    const layer = { ...newLayers[i] };
    layer.visible = !layer.visible;
    newLayers[i] = layer;
    onChange(newLayers);
  };

  const handleDeleteLayer = (id: number) => {
    const newLayers = value.filter((v) => v.id !== id);
    if (id === selected){
      setSelected(null)
    }
    onChange(newLayers);
  };

  const handleOrderChange = (oldIndex: number, newIndex: number) => {
    const currLayer = {...value[oldIndex]}
    const newLayers = [...value]
    newLayers.splice(oldIndex, 1)
    newLayers.splice(newIndex, 0, currLayer)
    onChange(newLayers)
  }

  return (
    <div>
      <AddGraphButton {...props} />
      <LayerList
        layers={value}
        handleSelect={(id) => (id !== selected ? setSelected(id) : setSelected(null))}
        selected={selected}
        handleNameChange={handleNameChange}
        handleToggleVisibility={handleToggleVisibility}
        handleDeleteLayer={handleDeleteLayer}
        handleOrderChange={handleOrderChange}
      />

      {selected !== null && (
        <WindDirectionEditor context={context} graphId={selected} graphs={value} handleUpdate={onChange} />
      )}
    </div>
  );
}
