import React, { useState } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { MapLayer, PlotType } from 'types';
import { AddLayerButton } from './AddLayerButton';
import { LayerList } from './LayerList';
import { Button, Checkbox } from '@grafana/ui';
import { MarkerEditor } from './MarkerEditor';
import { PlotSelector } from './PlotSelector';
import { LineEditor } from './LineEditor';
import { PopupEditor } from './PopupEditor';
import { VectorEditor } from './VectorEditor';

export const LayersEditor = (props: StandardEditorProps<MapLayer[]>) => {
  const { value, onChange, context } = props;
  const [selected, setSelected] = useState<number | null>(null);
  const selectedLayer = value.find((l) => l.id === selected);

  const handleNameChange = (id: number, newName: string) => {
    const newLayers = [...value];
    const i = newLayers.findIndex((l) => l.id === id);
    if (i === -1) {
      return;
    }
    const layer = { ...newLayers[i] };
    layer.name = newName;
    newLayers[i] = layer;
    onChange(newLayers);
  };

  const handleToggleVisibility = (id: number) => {
    const newLayers = [...value];
    const i = newLayers.findIndex((l) => l.id === id);
    if (i === -1) {
      return;
    }
    const layer = { ...newLayers[i] };
    layer.visible = !layer.visible;
    newLayers[i] = layer;
    onChange(newLayers);
  };

  const handleDeleteLayer = (id: number) => {
    const newLayers = value.filter((v) => v.id !== id);
    onChange(newLayers);
  };

  const handleToggleLegendVisibility = (id: number) => {
    const newLayers = [...value];
    const i = newLayers.findIndex((l) => l.id === id);
    if (i === -1) {
      return;
    }
    const layer = { ...newLayers[i] };
    layer.legendVisible = !layer.legendVisible;
    newLayers[i] = layer;
    console.log({newLayers})
    onChange(newLayers);
  };

  const handlePlotChange = (id: number, newPlot: PlotType) => {
    const i = value.findIndex((v) => v.id === id);
    if (i === -1) {
      return;
    }
    const layer = { ...value[i] };
    layer.plotInfo = newPlot;
    const newLayers = [...value];
    newLayers[i] = layer;
    onChange(newLayers);
  };

  const getEditor = (id: number) => {
    const i = value.findIndex((v) => v.id === id);
    if (i === -1) {
      return;
    }
    const layer = value[i];
    if (layer.plotInfo === undefined) {
      return null;
    } else if (layer.plotInfo.layerCategory === 'marker') {
      return <MarkerEditor layerId={id} layers={value} context={context} handleUpdate={onChange} />;
    } else if (layer.plotInfo.layerCategory === 'line') {
      return <LineEditor layerId={id} layers={value} context={context} handleUpdate={onChange} />;
    } else if (layer.plotInfo.layerCategory === 'vector') {
      return <VectorEditor layerId={id} layers={value} context={context} handleUpdate={onChange} />;
    } else {
      return null;
    }
  };

  const handleOrderChange = (oldIndex: number, newIndex: number) => {
    const currLayer = {...value[oldIndex]}
    const newLayers = [...value]
    newLayers.splice(oldIndex, 1)
    newLayers.splice(newIndex, 0, currLayer)
    onChange(newLayers)
  }


  return (
    <div style={{ minHeight: 400 }}>
      <div style={{ marginTop: 5, marginBottom: 20 }}>
        <AddLayerButton {...props} />
        <Button
          onClick={() => {
            onChange([]);
            setSelected(null);
          }}
        >
          Clear
        </Button>
      </div>

      <LayerList
        layers={value}
        handleSelect={(id) => setSelected(id)}
        selected={selected}
        handleNameChange={handleNameChange}
        handleToggleVisibility={handleToggleVisibility}
        handleDeleteLayer={handleDeleteLayer}
        handleOrderChange={handleOrderChange}
      />
      {selected !== null && <PlotSelector id={selected} layers={value} handleChange={handlePlotChange} />}
      {selectedLayer !== undefined && (
        <>
          <Checkbox
            checked={selectedLayer ? selectedLayer.legendVisible : false}
            onChange={() => handleToggleLegendVisibility(selectedLayer.id)}
          />
          <span className="checkbox-label">Legend visible?</span>
        </>
      )}
      {selected !== null && getEditor(selected)}
      {selected !== null && <PopupEditor layerId={selected} layers={value} handleChange={onChange} context={context} />}
    </div>
  );
};
