import React from 'react';
import { Select } from '@grafana/ui';
import { DataFrame, SelectableValue} from '@grafana/data';

interface ColumnSelectorProps {
  dataframe: DataFrame;
  handleChange: (selectorValue: string) => void;
  value?: string;
}

export const ColumnSelector = ({ value, handleChange, dataframe }: ColumnSelectorProps) => {
  const options: Array<SelectableValue<string>> = [];

  for (let i = 0; i < dataframe.fields.length; i++) {
    options.push({
      label: dataframe.fields[i].name,
      value: dataframe.fields[i].name,
    });
  }

  return (
    <>
      <Select
        key={value}
        options={options}
        value={value}
        onChange={(selectableValue) => {
          selectableValue.value !== undefined ? handleChange(selectableValue.value) : null;
        }}
      />
    </>
  );
};
