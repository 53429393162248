import React from 'react';
import { Select, Label } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { VectorShape } from 'utils/createMarker';

interface ShapeSelectorProps {
  handleChange: (selectorValue: VectorShape) => void;
  value: VectorShape
}

export const VectorShapeSelector = ({ value, handleChange }: ShapeSelectorProps) => {
  const shapes: Record<string, VectorShape> = {
    rectangle: VectorShape.Rectangle,
    ellipse: VectorShape.Ellipse,
    arrow: VectorShape.Arrow,
  };

  const options: Array<SelectableValue<string>> = [
    { label: 'rectangle', value: 'rectangle' },
    { label: 'ellipse', value: 'ellipse' },
    { label: 'arrow', value: 'arrow' },
  ];

  return (
    <>
      <Label>Marker Icon</Label>
      <Select
        key={value}
        options={options}
        value={Object.keys(shapes).find(k => shapes[k] === value)}
        onChange={(selectableValue) => {
          selectableValue.value !== undefined ? handleChange(shapes[selectableValue.value]) : null;
        }}
      />
    </>
  );
};
