import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { MapLayer, SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView } from '@grafana/runtime';
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Polyline, Circle } from 'react-leaflet';
import { getIcon, getVectorIcon, Shape, VectorShape } from 'utils/createMarker';
import Color from 'colorjs.io';
import Ellipse from './Ellipse';

import 'leaflet/dist/leaflet.css';
import googleLogo from '../img/google_on_non_white.png';
import './SimplePanel.css';
import { MapListener } from './MapListener';
import { Legend } from './Legend';
import { GraphPanel } from './GraphPanel';
type rowInfo = { name: string; values: any[]; units: string; dataframe: string };
const INITIAL_ZOOM = 17;

interface Props extends PanelProps<SimpleOptions> {}
interface MapSrc {
  url: string;
  attribution: string;
}

const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

enum MapTypes {
  StreetView,
  GoogleSatelliteView,
}

const MapViews: Record<MapTypes, MapSrc> = {
  [MapTypes.StreetView]: {
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
  },
  [MapTypes.GoogleSatelliteView]: {
    url: 'http://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    attribution: `&copy;${new Date().getFullYear()} Google`,
  },
};

function getProportionalPosition(min: number, max: number, x: number) {
  if (min === max) {
    return 0.5;
  }
  return (x - min) / (max - min);
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  // const theme = useTheme2();

  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const styles = useStyles2(getStyles);
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.StreetView);
  const { layers, graphs } = options;
  const visibility_obj: Record<number, boolean> = {};
  for (const layer of layers) {
    visibility_obj[layer.id] = true;
  }

  const [layerVisibility, setLayerVisibility] = useState(visibility_obj);
  const [rerender, setRerender] = useState(true);
  useEffect(() => {
    setRerender((r) => !r);
  }, [data]);
  useEffect(() => {
    setZoom(INITIAL_ZOOM);
  }, [width, height]);

  useEffect(() => {
    setLayerVisibility((prevVisible) => {
      const visibility_obj: Record<number, boolean> = {};
      for (const layer of layers) {
        if (layer.id in prevVisible) {
          visibility_obj[layer.id] = prevVisible[layer.id];
        } else {
          visibility_obj[layer.id] = true;
        }
      }
      return visibility_obj;
    });
  }, [layers]);

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }
  let summedLongitudes = 0;
  let summedLatitudes = 0;
  let numberOfData = 0;

  const constructPopup = (
    name: string,
    popupInfo: Record<number, rowInfo>,
    dataframeI: string,
    i: number,
    dataframeJ?: string,
    j?: number
  ) => {
    const rows: React.JSX.Element[] = [];
    Object.entries(popupInfo).forEach(([rowId, rowInfo]) => {
      if (rowInfo.dataframe === dataframeI) {
        rows.push(<p key={rowId}>{`${rowInfo.name}: ${rowInfo.values[i]}${rowInfo.units}`}</p>);
      } else if (dataframeJ !== undefined && j !== undefined) {
        rows.push(<p key={rowId}>{`${rowInfo.name}: ${rowInfo.values[j]}${rowInfo.units}`}</p>);
      }
    });
    if (rows.length <= 0) {
      return null;
    }

    return (
      <Popup>
        <p>{name}</p>
        {...rows}
      </Popup>
    );
  };

  const getMarkerLayer = (layer: MapLayer, offset?: number, popupInfo?: Record<number, rowInfo>) => {
    if (layer.plotInfo === undefined || layer.plotInfo.layerCategory !== 'marker') {
      return null;
    }
    const plotInfo = layer.plotInfo;
    const layerMarkers: React.JSX.Element[] = [];
    const series = data.series.find((df) => df.refId === plotInfo.dataframe);
    const latitude = series?.fields.find((field) => field.name === 'latitude');
    const longitude = series?.fields.find((field) => field.name === 'longitude');
    const latitudeValues = latitude?.values as number[] | undefined;
    const longitudeValues = longitude?.values as number[] | undefined;
    if (latitudeValues !== undefined && longitudeValues !== undefined) {
      let layerMarker;
      let colorScheme;
      let colorScalar;
      let colorMax = 0;
      let colorMin = 0;
      let sizeScalar;
      let sizeMax = 0;
      let sizeMin = 0;
      let opacityScalar;
      let opacityMax = 0;
      let opacityMin = 0;

      if (plotInfo.opacityScale !== undefined) {
        const scaleColumn = plotInfo.opacityScale.column;
        const scale = series?.fields.find((field) => field.name === scaleColumn);
        opacityScalar = scale?.values as number[] | undefined;
        if (opacityScalar === undefined) {
          return null;
        }
        opacityMax = Math.max(...opacityScalar);
        opacityMin = Math.min(...opacityScalar);
      }

      if (plotInfo.markerColor.colorCategory === 'scale') {
        const scaleColumn = plotInfo.markerColor.column;
        const [colorString1, colorString2] = plotInfo.markerColor.colorScheme;
        const c1 = new Color(colorString1);
        const c2 = new Color(colorString2);
        colorScheme = c1.range(c2, { space: plotInfo.markerColor.colorSpace, hue: plotInfo.markerColor.colorHue });
        const scale = series?.fields.find((field) => field.name === scaleColumn);
        colorScalar = scale?.values as number[] | undefined;
        if (colorScalar === undefined) {
          return null;
        }
        colorMax = Math.max(...colorScalar);
        colorMin = Math.min(...colorScalar);
      }

      if (plotInfo.markerSize.sizeCategory === 'scale') {
        const scaleColumn = plotInfo.markerSize.column;
        const scale = series?.fields.find((field) => field.name === scaleColumn);
        sizeScalar = scale?.values as number[] | undefined;
        if (sizeScalar === undefined) {
          return null;
        }
        sizeMax = Math.max(...sizeScalar);
        sizeMin = Math.min(...sizeScalar);
      }

      for (let i = 0; i < latitudeValues.length; i++) {
        const lat = latitudeValues[i];
        const long = longitudeValues[i];
        summedLatitudes += lat;
        summedLongitudes += long;
        numberOfData += 1;
        let popup;
        if (popupInfo !== undefined && plotInfo.dataframe !== undefined) {
          popup = constructPopup(layer.name, popupInfo, plotInfo.dataframe, i);
        }
        if (layer.plotInfo.markerType === Shape.Circle && layer.plotInfo.zoomInvariant) {
          let color;
          if (plotInfo.markerColor.colorCategory === 'fixed') {
            color = new Color(plotInfo.markerColor.color);
          } else if (
            plotInfo.markerColor.colorCategory === 'scale' &&
            colorScheme !== undefined &&
            colorScalar !== undefined
          ) {
            color = colorScheme(getProportionalPosition(colorMin, colorMax, colorScalar[i]));
          }
          if (color === undefined) {
            continue;
          }

          let opacity;
          if (opacityScalar !== undefined && plotInfo.opacityScale !== undefined) {
            opacity =
              getProportionalPosition(opacityMin, opacityMax, opacityScalar[i]) *
                (plotInfo.opacityScale.maxOpacity - plotInfo.opacityScale.minOpacity) +
              plotInfo.opacityScale.minOpacity;
          }

          let size;
          if (plotInfo.markerSize.sizeCategory === 'scale' && sizeScalar !== undefined) {
            if (plotInfo.markerSize.auto) {
              size = sizeScalar[i] >= 0 ? sizeScalar[i] : 0;
            } else if (plotInfo.markerSize.maxValue !== undefined && plotInfo.markerSize.minValue !== undefined) {
              size =
                getProportionalPosition(sizeMin, sizeMax, sizeScalar[i]) *
                  (plotInfo.markerSize.maxValue - plotInfo.markerSize.minValue) +
                plotInfo.markerSize.minValue;
            } else {
              return null;
            }
          } else if (plotInfo.markerSize.sizeCategory === 'fixed') {
            size = plotInfo.markerSize.size;
          } else {
            return null;
          }

          const circle = (
            <Circle
              key={`${color.toString()}-${lat}-${long}-${layer.id}-${opacity}`}
              center={[lat, long]}
              radius={size / 2}
              color={color.toString()}
              opacity={0}
              fillOpacity={opacity}
            >
              {popup}
            </Circle>
          );
          layerMarkers.push(circle);
          continue;
        }

        layerMarker = getIcon.bind(null, plotInfo.markerType);
        if (plotInfo.markerColor.colorCategory === 'scale' && colorScheme !== undefined && colorScalar !== undefined) {
          const color = colorScheme(getProportionalPosition(colorMin, colorMax, colorScalar[i]));
          if (opacityScalar !== undefined && plotInfo.opacityScale !== undefined) {
            const alpha = getProportionalPosition(opacityMin, opacityMax, opacityScalar[i]);
            color.alpha =
              alpha * (plotInfo.opacityScale.maxOpacity - plotInfo.opacityScale.minOpacity) +
              plotInfo.opacityScale.minOpacity;
          }
          layerMarker = layerMarker.bind(null, color.toString());
        } else if (plotInfo.markerColor.colorCategory === 'fixed') {
          const color = new Color(plotInfo.markerColor.color);
          if (opacityScalar !== undefined && plotInfo.opacityScale !== undefined) {
            const alpha = getProportionalPosition(opacityMin, opacityMax, opacityScalar[i]);
            color.alpha =
              alpha * (plotInfo.opacityScale.maxOpacity - plotInfo.opacityScale.minOpacity) +
              plotInfo.opacityScale.minOpacity;
          }
          layerMarker = layerMarker.bind(null, color.toString());
        } else {
          return null;
        }

        let scale = 1;
        if (plotInfo.zoomInvariant) {
          scale = 2 ** (zoom - 18);
        }
        if (plotInfo.markerSize.sizeCategory === 'scale' && sizeScalar !== undefined) {
          let size;
          if (plotInfo.markerSize.auto) {
            size = sizeScalar[i] >= 0 ? sizeScalar[i] : 0;
          } else if (plotInfo.markerSize.maxValue !== undefined && plotInfo.markerSize.minValue !== undefined) {
            size =
              getProportionalPosition(sizeMin, sizeMax, sizeScalar[i]) *
                (plotInfo.markerSize.maxValue - plotInfo.markerSize.minValue) +
              plotInfo.markerSize.minValue;
          } else {
            return null;
          }
          layerMarker = layerMarker(Math.floor(scale * size));
        } else if (plotInfo.markerSize.sizeCategory === 'fixed') {
          layerMarker = layerMarker(plotInfo.markerSize.size * scale);
        } else {
          return null;
        }

        const marker = (
          <Marker
            key={`${layer.id}-${i}`}
            position={[lat, long]}
            icon={layerMarker}
            zIndexOffset={offset !== undefined ? offset : 0}
          >
            {popup}
          </Marker>
        );
        layerMarkers.push(marker);
      }
      return {
        markerLayer: <LayerGroup key={`layer-${layer.id}`}>{...layerMarkers}</LayerGroup>,
        count: layerMarkers.length,
      };
    } else {
      return null;
    }
  };

  const getVectorLayer = (layer: MapLayer, offset?: number, popupInfo?: Record<number, rowInfo>) => {
    if (layer.plotInfo === undefined || layer.plotInfo.layerCategory !== 'vector') {
      return null;
    }
    const plotInfo = layer.plotInfo;
    const layerMarkers: React.JSX.Element[] = [];
    const series = data.series.find((df) => df.refId === plotInfo.dataframe);
    const latitude = series?.fields.find((field) => field.name === 'latitude');
    const longitude = series?.fields.find((field) => field.name === 'longitude');
    const rotation = series?.fields.find((field) => field.name === 'rotation');
    const latitudeValues = latitude?.values as number[] | undefined;
    const longitudeValues = longitude?.values as number[] | undefined;
    const rotationValues = rotation?.values as number[] | undefined;
    if (latitudeValues === undefined || longitudeValues === undefined || rotationValues === undefined) {
      return null;
    }
    let layerMarker;
    let colorScheme;
    let colorScalar;
    let colorMax = 0;
    let colorMin = 0;
    let lengthScalar;
    let lengthMax = 0;
    let lengthMin = 0;
    let widthScalar;
    let widthMax = 0;
    let widthMin = 0;
    let opacityScalar;
    let opacityMax = 0;
    let opacityMin = 0;

    if (plotInfo.opacityScale !== undefined) {
      const scaleColumn = plotInfo.opacityScale.column;
      const scale = series?.fields.find((field) => field.name === scaleColumn);
      opacityScalar = scale?.values as number[] | undefined;
      if (opacityScalar === undefined) {
        return null;
      }
      opacityMax = Math.max(...opacityScalar);
      opacityMin = Math.min(...opacityScalar);
    }

    if (plotInfo.markerColor.colorCategory === 'scale') {
      const scaleColumn = plotInfo.markerColor.column;
      const [colorString1, colorString2] = plotInfo.markerColor.colorScheme;
      const c1 = new Color(colorString1);
      const c2 = new Color(colorString2);
      colorScheme = c1.range(c2, { space: plotInfo.markerColor.colorSpace, hue: plotInfo.markerColor.colorHue });
      const scale = series?.fields.find((field) => field.name === scaleColumn);
      colorScalar = scale?.values as number[] | undefined;
      if (colorScalar === undefined) {
        return null;
      }
      colorMax = Math.max(...colorScalar);
      colorMin = Math.min(...colorScalar);
    }

    if (plotInfo.vectorLength.sizeCategory === 'scale') {
      const scaleColumn = plotInfo.vectorLength.column;
      const scale = series?.fields.find((field) => field.name === scaleColumn);
      lengthScalar = scale?.values as number[] | undefined;
      if (lengthScalar === undefined) {
        return null;
      }
      lengthMax = Math.max(...lengthScalar);
      lengthMin = Math.min(...lengthScalar);
    }

    if (plotInfo.vectorWidth.sizeCategory === 'scale') {
      const scaleColumn = plotInfo.vectorWidth.column;
      const scale = series?.fields.find((field) => field.name === scaleColumn);
      widthScalar = scale?.values as number[] | undefined;
      if (widthScalar === undefined) {
        return null;
      }
      widthMax = Math.max(...widthScalar);
      widthMin = Math.min(...widthScalar);
    }

    for (let i = 0; i < latitudeValues.length; i++) {
      const lat = latitudeValues[i];
      const long = longitudeValues[i];
      const rotation = rotationValues[i];
      summedLatitudes += lat;
      summedLongitudes += long;
      numberOfData += 1;
      let popup;
      if (popupInfo !== undefined && plotInfo.dataframe !== undefined) {
        popup = constructPopup(layer.name, popupInfo, plotInfo.dataframe, i);
      }

      if (layer.plotInfo.markerType === VectorShape.Ellipse && layer.plotInfo.zoomInvariant) {
        let color;
        if (plotInfo.markerColor.colorCategory === 'fixed') {
          color = new Color(plotInfo.markerColor.color);
        } else if (
          plotInfo.markerColor.colorCategory === 'scale' &&
          colorScheme !== undefined &&
          colorScalar !== undefined
        ) {
          color = colorScheme(getProportionalPosition(colorMin, colorMax, colorScalar[i]));
        }
        if (color === undefined) {
          continue;
        }

        let opacity;
        if (opacityScalar !== undefined && plotInfo.opacityScale !== undefined) {
          opacity =
            getProportionalPosition(opacityMin, opacityMax, opacityScalar[i]) *
              (plotInfo.opacityScale.maxOpacity - plotInfo.opacityScale.minOpacity) +
            plotInfo.opacityScale.minOpacity;
        }

        let length;
        if (plotInfo.vectorLength.sizeCategory === 'scale' && lengthScalar !== undefined) {
          if (plotInfo.vectorLength.auto) {
            length = lengthScalar[i] >= 0 ? lengthScalar[i] : 0;
          } else if (plotInfo.vectorLength.maxValue !== undefined && plotInfo.vectorLength.minValue !== undefined) {
            length =
              getProportionalPosition(lengthMin, lengthMax, lengthScalar[i]) *
                (plotInfo.vectorLength.maxValue - plotInfo.vectorLength.minValue) +
              plotInfo.vectorLength.minValue;
          } else {
            return null;
          }
        } else if (plotInfo.vectorLength.sizeCategory === 'fixed') {
          length = plotInfo.vectorLength.size;
        } else {
          return null;
        }

        let width;
        if (plotInfo.vectorWidth.sizeCategory === 'scale' && widthScalar !== undefined) {
          if (plotInfo.vectorWidth.auto) {
            width = widthScalar[i] >= 0 ? widthScalar[i] : 0;
          } else if (plotInfo.vectorWidth.maxValue !== undefined && plotInfo.vectorWidth.minValue !== undefined) {
            width =
              getProportionalPosition(widthMin, widthMax, widthScalar[i]) *
                (plotInfo.vectorWidth.maxValue - plotInfo.vectorWidth.minValue) +
              plotInfo.vectorWidth.minValue;
          } else {
            return null;
          }
        } else if (plotInfo.vectorWidth.sizeCategory === 'fixed') {
          width = plotInfo.vectorWidth.size;
        } else {
          return null;
        }

        const ellipse = (
          <Ellipse
            center={[lat, long]}
            radii={[width / 2, length / 2]}
            tilt={rotation}
            options={{
              color: color,
              fillColor: color,
              fillOpacity: opacity,
              opacity: 1,
              weight: 0,
            }}
          >
            {popup}
          </Ellipse>
        );
        layerMarkers.push(ellipse);
        continue;
      }

      layerMarker = getVectorIcon.bind(null, plotInfo.markerType);
      if (plotInfo.markerColor.colorCategory === 'scale' && colorScheme !== undefined && colorScalar !== undefined) {
        const color = colorScheme(getProportionalPosition(colorMin, colorMax, colorScalar[i]));
        if (opacityScalar !== undefined && plotInfo.opacityScale !== undefined) {
          const alpha = getProportionalPosition(opacityMin, opacityMax, opacityScalar[i]);
          color.alpha =
            alpha * (plotInfo.opacityScale.maxOpacity - plotInfo.opacityScale.minOpacity) +
            plotInfo.opacityScale.minOpacity;
        }
        layerMarker = layerMarker.bind(null, color.toString());
      } else if (plotInfo.markerColor.colorCategory === 'fixed') {
        const color = new Color(plotInfo.markerColor.color);
        if (opacityScalar !== undefined && plotInfo.opacityScale !== undefined) {
          const alpha = getProportionalPosition(opacityMin, opacityMax, opacityScalar[i]);
          color.alpha =
            alpha * (plotInfo.opacityScale.maxOpacity - plotInfo.opacityScale.minOpacity) +
            plotInfo.opacityScale.minOpacity;
        }
        layerMarker = layerMarker.bind(null, color.toString());
      } else {
        return null;
      }

      layerMarker = layerMarker.bind(null, rotation);

      let lengthScale = 1;
      if (plotInfo.zoomInvariant) {
        lengthScale = 2 ** (zoom - 18);
      }
      if (plotInfo.vectorLength.sizeCategory === 'scale' && lengthScalar !== undefined) {
        let length;
        if (plotInfo.vectorLength.auto) {
          length = lengthScalar[i] >= 0 ? lengthScalar[i] : 0;
        } else if (plotInfo.vectorLength.maxValue !== undefined && plotInfo.vectorLength.minValue !== undefined) {
          length =
            getProportionalPosition(lengthMin, lengthMax, lengthScalar[i]) *
              (plotInfo.vectorLength.maxValue - plotInfo.vectorLength.minValue) +
            plotInfo.vectorLength.minValue;
        } else {
          return null;
        }
        layerMarker = layerMarker.bind(null, Math.floor(lengthScale * length));
      } else if (plotInfo.vectorLength.sizeCategory === 'fixed') {
        layerMarker = layerMarker.bind(null, plotInfo.vectorLength.size * lengthScale);
      } else {
        return null;
      }

      let widthScale = 1;
      if (plotInfo.zoomInvariant) {
        widthScale = 2 ** (zoom - 18);
      }
      if (plotInfo.vectorWidth.sizeCategory === 'scale' && widthScalar !== undefined) {
        let width;
        if (plotInfo.vectorWidth.auto) {
          width = widthScalar[i] >= 0 ? widthScalar[i] : 0;
        } else if (plotInfo.vectorWidth.maxValue !== undefined && plotInfo.vectorWidth.minValue !== undefined) {
          width =
            getProportionalPosition(widthMin, widthMax, widthScalar[i]) *
              (plotInfo.vectorWidth.maxValue - plotInfo.vectorWidth.minValue) +
            plotInfo.vectorWidth.minValue;
        } else {
          return null;
        }
        layerMarker = layerMarker(Math.floor(widthScale * width));
      } else if (plotInfo.vectorWidth.sizeCategory === 'fixed') {
        layerMarker = layerMarker(plotInfo.vectorWidth.size * widthScale);
      } else {
        return null;
      }

      const marker = (
        <Marker
          key={`${layer.id}-${i}`}
          position={[lat, long]}
          icon={layerMarker}
          zIndexOffset={offset !== undefined ? offset : 0}
        >
          {popup}
        </Marker>
      );
      layerMarkers.push(marker);
    }
    return {
      vectorLayer: <LayerGroup key={`layer-${layer.id}`}>{...layerMarkers}</LayerGroup>,
      count: layerMarkers.length,
    };
  };

  const getLineLayer = (layer: MapLayer, offset?: number, popupInfo?: Record<number, rowInfo>) => {
    if (layer.plotInfo === undefined || layer.plotInfo.layerCategory !== 'line') {
      return null;
    }

    const plotInfo = layer.plotInfo;

    const layerLines: React.JSX.Element[] = [];
    const series1 = data.series.find((df) => df.refId === plotInfo.dataframe1);
    const series2 = data.series.find((df) => df.refId === plotInfo.dataframe2);
    const latitude1 = series1?.fields.find((field) => field.name === 'latitude');
    const longitude1 = series1?.fields.find((field) => field.name === 'longitude');
    const latitude1Values = latitude1?.values as number[] | undefined;
    const longitude1Values = longitude1?.values as number[] | undefined;
    const latitude2 = series2?.fields.find((field) => field.name === 'latitude');
    const longitude2 = series2?.fields.find((field) => field.name === 'longitude');
    const latitude2Values = latitude2?.values as number[] | undefined;
    const longitude2Values = longitude2?.values as number[] | undefined;
    if (
      latitude1Values !== undefined &&
      longitude1Values !== undefined &&
      latitude2Values !== undefined &&
      longitude2Values !== undefined
    ) {
      for (let i = 0; i < latitude1Values.length; i++) {
        const lat1 = latitude1Values[i];
        const long1 = longitude1Values[i];
        for (let j = 0; j < latitude2Values.length; j++) {
          const lat2 = latitude2Values[j];
          const long2 = longitude2Values[j];
          let popup;
          if (plotInfo.dataframe1 !== undefined && plotInfo.dataframe2 !== undefined && popupInfo !== undefined) {
            popup = constructPopup(layer.name, popupInfo, plotInfo.dataframe1, i, plotInfo.dataframe2, j);
          }
          summedLatitudes += lat1 + lat2;
          summedLongitudes += long1 + long2;
          numberOfData += 2;
          layerLines.push(
            <Polyline
              key={`layer-${layer.id}-${i}-${j}-${layer.plotInfo.lineColor}`}
              positions={[
                [lat1, long1],
                [lat2, long2],
              ]}
              weight={2}
              color={layer.plotInfo.lineColor}
            >
              {popup}
            </Polyline>
          );
        }
      }
      return <LayerGroup key={`layer-${layer.id}`}>{...layerLines}</LayerGroup>;
    } else {
      return null;
    }
  };

  const mapLayers: React.JSX.Element[] = [];
  let currentOffset = 0;
  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    if (!layer.visible || layerVisibility[layer.id] === false) {
      continue;
    }

    const popupInfo: Record<number, rowInfo> = {};
    for (let row of layer.popup) {
      if (!row.visible) {
        continue;
      }
      let dataframeName;
      if (row.dataframe !== undefined) {
        dataframeName = row.dataframe;
      } else {
        continue;
      }

      const dataframe = data.series.find((df) => df.refId === dataframeName);
      if (dataframe === undefined) {
        continue;
      }

      const column = dataframe.fields.find((f) => f.name === row.column);
      if (column === undefined) {
        continue;
      }

      popupInfo[row.id] = { name: row.name, values: [...column.values], units: row.units, dataframe: dataframeName };
    }

    if (layer.plotInfo?.layerCategory === 'marker') {
      const markerLayerObj = getMarkerLayer(layer, currentOffset, popupInfo);

      if (markerLayerObj !== null) {
        const { markerLayer, count } = markerLayerObj;
        mapLayers.push(markerLayer);
        currentOffset += count;
      }
    } else if (layer.plotInfo?.layerCategory === 'line') {
      const lineLayer = getLineLayer(layer, currentOffset, popupInfo);
      if (lineLayer !== null) {
        mapLayers.push(lineLayer);
      }
    } else if (layer.plotInfo?.layerCategory === 'vector') {
      const vectorLayerObj = getVectorLayer(layer, currentOffset, popupInfo);

      if (vectorLayerObj !== null) {
        const { vectorLayer, count } = vectorLayerObj;
        mapLayers.push(vectorLayer);
        currentOffset += count;
      }
    }
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
          user-select: none;
        `
      )}
    >
      <MapContainer
        key={`map-${width}-${height}-${rerender}`}
        center={[
          numberOfData !== 0 ? summedLatitudes / numberOfData : 0,
          numberOfData !== 0 ? summedLongitudes / numberOfData : 0,
        ]}
        zoom={INITIAL_ZOOM}
        scrollWheelZoom={true}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer {...MapViews[mapType]} />
        {mapLayers}

        <MapListener
          key={`listener-${width}-${height}`}
          onZoomChange={(newZoom) => {
            setZoom(newZoom);
          }}
        />

        <div className="leaflet-top leaflet-right">
          <div className="leaflet-control leaflet-bar map-control view-selector-container">
            <input
              type="radio"
              id={`${id}-street-option`}
              name={`${id}-selector}`}
              className="selector"
              onClick={() => {
                setMapType(MapTypes.StreetView);
              }}
              value={'street'}
              key={`${mapType}`}
              checked={mapType === MapTypes.StreetView}
            />
            <label htmlFor={`${id}-street-option`} className="view-selector">
              Street
            </label>
            <input
              type="radio"
              id={`${id}-satellite-option`}
              name={`${id}-selector}`}
              className="selector"
              onClick={() => {
                setMapType(MapTypes.GoogleSatelliteView);
              }}
              value={'satellite'}
              key={`${mapType}`}
              checked={mapType === MapTypes.GoogleSatelliteView}
              width={75}
            />
            <label htmlFor={`${id}-satellite-option`} className="view-selector">
              Satellite
            </label>
          </div>
        </div>
        <GraphPanel graphs={graphs} data={data} />
        <Legend
          layers={layers}
          layerVisibility={layerVisibility}
          setVisibility={(id: number) => {
            setLayerVisibility((l) => {
              const newLayerVisibility = { ...l };
              newLayerVisibility[id] = !newLayerVisibility[id];
              return newLayerVisibility;
            });
          }}
        />

        {mapType === MapTypes.GoogleSatelliteView && (
          <div className="leaflet-bottom leaflet-left">
            <div className="leaflet-control leaflet-bar" id="google-logo">
              <img src={googleLogo} alt="logo of google" />
            </div>
          </div>
        )}
      </MapContainer>
    </div>
  );
};
