import React from 'react';
import { useMapEvent } from 'react-leaflet';

export interface MapListenerProps {
  onZoomChange?: (newZoom: number) => void;
}
export function MapListener({ onZoomChange }: MapListenerProps) {
  useMapEvent('zoomend', (e) => {
    if (onZoomChange !== undefined) {
      onZoomChange(e.target.getZoom());
    }
  });
  return <></>;
}
