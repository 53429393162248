import { DataFrame } from '@grafana/data';
import { ColorPicker, Label, Select } from '@grafana/ui';
import React from 'react';
import { ColumnSelector } from './ColumnSelector';
import { ColorHue, colorhues, ColorSpace, colorspaces } from 'types';

export interface ColorSchemeEditorProps {
  column?: string;
  dataframe: DataFrame;
  color1: string;
  color2: string;
  colorspace: ColorSpace;
  colorhue: ColorHue;
  handleColor1Change: (color1: string) => void;
  handleColor2Change: (color2: string) => void;
  handleColumnChange: (column: string) => void;
  handleColorSpaceChange: (colorSpace: ColorSpace) => void;
  handleColorHueChange: (colorHue: ColorHue) => void;
}

export function ColorSchemeEditor({
  column,
  dataframe,
  color1,
  color2,
  colorspace,
  colorhue,
  handleColor1Change,
  handleColor2Change,
  handleColumnChange,
  handleColorHueChange,
  handleColorSpaceChange,
}: ColorSchemeEditorProps) {
  const colorSpaceOptions = colorspaces.map((c) => {
    return { label: c, value: c };
  });
  const colorHueOptions = colorhues.map((c) => {
    return { label: c, value: c };
  });

  return (
    <>
      <Label>Column</Label>
      <ColumnSelector value={column} dataframe={dataframe} handleChange={handleColumnChange} />
      <Label>Color 1</Label>
      <ColorPicker color={color1} onChange={handleColor1Change}></ColorPicker>
      <Label>Color 2</Label>
      <ColorPicker color={color2} onChange={handleColor2Change}></ColorPicker>
      <Label>Color Space</Label>
      <Select
        key={colorspace}
        options={colorSpaceOptions}
        value={colorspace}
        onChange={(selectableValue) => {
          selectableValue.value !== undefined && handleColorSpaceChange(selectableValue.value);
        }}
      />
      <Label>Color Hue</Label>
      <Select
        key={colorhue}
        options={colorHueOptions}
        value={colorhue}
        onChange={(selectableValue) => {
          selectableValue.value !== undefined && handleColorHueChange(selectableValue.value);
        }}
      />
    </>
  );
}
