export function getArrowHTML(length: number, width: number, rotation: number) {
  let iconLength = length >= 8 ? length : 8;
  let iconWidth = width >= 10 ? width : 10;

  return `<div style='height: ${width}px; width: ${width}px '><svg style="transform: rotate(${rotation}deg); position: absolute; top: ${
    width / 2 - iconLength / 2
  }px"; viewBox="0 0 ${iconWidth} ${iconLength + 3}" fill='{color}' xmlns="http://www.w3.org/2000/svg">
<path d="M${iconWidth - 3},${iconLength + 3}L${iconWidth / 2},${(iconLength + 3) / 1.5}L3,${iconLength + 3}L${
    iconWidth / 2
  },3Z"/>
</svg></div>`;
}

export function getEllipseHTML(length: number, width: number, rotation: number) {
  let iconLength = length >= 8 ? length : 8;
  let iconWidth = width >= 10 ? width : 10;

  return `<div style='height: ${iconWidth}px; width: ${iconWidth}px '><svg style="transform: rotate(${rotation}deg); position: absolute; top: ${
    iconWidth / 2 - iconLength / 2
  }px"; viewBox="0 0 ${iconWidth} ${iconLength}" fill='{color}' xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="${iconWidth / 2}" cy="${iconLength / 2}" rx="${iconWidth / 2}" ry="${iconLength / 2}" />
  </svg></div>`;
}

export function getRectangleHTML(length: number, width: number, rotation: number) {
  let iconLength = length >= 8 ? length : 8;
  let iconWidth = width >= 10 ? width : 10;

  return `<div style='height: ${iconWidth}px; width: ${iconWidth}px '><svg style="transform: rotate(${rotation}deg); position: absolute; top: ${
    iconWidth / 2 - iconLength / 2
  }px"; viewBox="0 0 ${iconWidth} ${iconLength}" fill='{color}' xmlns="http://www.w3.org/2000/svg">
  <rect width=${iconWidth} height=${iconLength} />
  </svg></div>`;
}
