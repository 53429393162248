import React from 'react';
import { Button } from '@grafana/ui';
import { MapLayer } from 'types';

export interface AddPopupRowButtonProps {
  layerId: number;
  layers: MapLayer[];
  onChange: (newLayers: MapLayer[]) => void;
}

export const AddPopupRowButton = (props: AddPopupRowButtonProps) => {
  const { layerId, layers, onChange } = props;
  const layer = layers.find((l) => l.id === layerId);
  if (layer === undefined) {
    return null;
  }
  return (
    <Button
      name="Add Row"
      onClick={() => {
        let id = 0;
        for (const row of layer.popup) {
          if (id <= row.id) {
            id = row.id + 1;
          }
        }

        const newPopup = [...layer.popup];
        newPopup.push({ id, name: `row-${id}`, visible: true, units: '' });

        const newLayer: MapLayer = {
          ...layer,
          popup: newPopup,
        };

        const newLayers = [...layers];
        const i = newLayers.findIndex((l) => l.id === layerId);
        newLayers[i] = newLayer;
        onChange(newLayers);
      }}
    >
      Add Row
    </Button>
  );
};
