import { Shape, VectorShape } from 'utils/createMarker';

export interface Items {
  id: number;
  name: string;
  visible: boolean;
}

export interface MapLayer extends Items {
  plotInfo?: VectorLayer | LineLayer | MarkerLayer;
  legendVisible: boolean
  popup: PopupRow[];
}

export interface SimpleOptions {
  text: string;
  label: string;
  layers: MapLayer[];
  graphs: Graph[];
}

export interface PopupRow extends Items {
  dataframe?: string;
  column?: string;
  units: string;
}

export interface MarkerLayer {
  layerCategory: 'marker';
  dataframe?: string;
  latitudeColumn?: string;
  longitudeColumn?: string;
  markerType: Shape;
  markerColor: MarkerColor;
  opacityScale?: OpacityScale;
  markerSize: MarkerSize;
  zoomInvariant: boolean;
}

export type MarkerColor = ColorScale | FixedColor;

export interface FixedColor {
  colorCategory: 'fixed';
  color: string;
}

export interface ColorScale {
  colorCategory: 'scale';
  column?: string;
  colorScheme: [string, string];
  colorSpace: ColorSpace;
  colorHue: ColorHue;
  minValue: number | null;
  maxValue: number | null;
}

export type ColorSpace = 'lch' | 'srgb' | 'srgb-linear' | 'hsl' | 'hwb';
export type ColorHue = 'longer' | 'shorter' | 'increasing' | 'decreasing' | 'raw';

export const colorspaces: ColorSpace[] = ['lch', 'srgb', 'srgb-linear', 'hsl', 'hwb'];
export const colorhues: ColorHue[] = ['longer', 'shorter', 'increasing', 'decreasing', 'raw'];

export interface OpacityScale {
  column: string;
  minOpacity: number;
  maxOpacity: number;
}

export type MarkerSize = SizeScale | FixedSize;

export interface FixedSize {
  sizeCategory: 'fixed';
  size: number;
}

export interface SizeScale {
  sizeCategory: 'scale';
  column?: string;
  auto: boolean;
  minValue?: number;
  maxValue?: number;
}

export interface LineLayer {
  layerCategory: 'line';
  dataframe1?: string;
  dataframe2?: string;
  latitude1Column?: string;
  longitude1Column?: string;
  latitude2Column?: string;
  longitude2Column?: string;
  lineColor: string;
  lineThickness: number;
}

export enum VectorPlotType {
  ByColor,
  ByLength,
  ByThickness,
}

export interface VectorLayer {
  layerCategory: 'vector';
  dataframe?: string;
  latitudeColumn?: string;
  longitudeColumn?: string;
  rotationColumn?: string;
  markerType: VectorShape;
  markerColor: MarkerColor;
  opacityScale?: OpacityScale;
  vectorLength: MarkerSize;
  vectorWidth: MarkerSize;
  zoomInvariant: boolean;
}

export type PlotType = MarkerLayer | VectorLayer | LineLayer;

export interface Graph extends Items {
  plotInfo?: WindDirection;
}

export interface WindDirection {
  dataframe?: string;
  plotCategory: 'wind-direction';
  windDirectionColumn: string;
  windSpeedColumn: string;
  turbulenceColumn: string;
}
