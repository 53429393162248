import React from 'react';

export interface SVGProps {
  color: string;
  size: number;
  leftOffset: number;
}

const legendStyle = (leftOffset: number) =>
  ({
    position: 'absolute',
    marginLeft: leftOffset,
  } as React.CSSProperties);

export function Circle({ color, size, leftOffset }: SVGProps) {
  return (
    <svg
      style={legendStyle(leftOffset)}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={color} />
    </svg>
  );
}

export function Square({ color, size, leftOffset }: SVGProps) {
  return (
    <svg
      style={legendStyle(leftOffset)}
      fill={color}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 6 6 L 6 26 L 26 26 L 26 6 L 6 6 z" />
    </svg>
  );
}

export function Triangle({ color, size, leftOffset }: SVGProps) {
  return (
    <svg
      style={legendStyle(leftOffset)}
      fill={color}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21,21H3L12,3Z" />
    </svg>
  );
}

export function Arrow({ color, size, leftOffset }: SVGProps) {
  return (
    <svg
      style={legendStyle(leftOffset)}
      fill={color}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21,21L12,16L3,21L12,3Z" />
    </svg>
  );
}

export function Ellipse({ color, size, leftOffset }: SVGProps) {
  return (
    <svg
      style={legendStyle(leftOffset)}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="8" cy="8" rx="5" ry="8" fill={color} />
    </svg>
  );
}

export function Marker({ color, size, leftOffset }: SVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="-4 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={legendStyle(leftOffset)}
    >
      <title>map-marker</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id="Vivid.JS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">
          <g id="Icons" transform="translate(37.000000, 169.000000)">
            <g id="map-marker" transform="translate(78.000000, 468.000000)">
              <g transform="translate(10.000000, 6.000000)">
                <path
                  d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"
                  id="Shape"
                  fill={color}
                ></path>
                <circle id="Oval" fill="#0C0058" fillRule="nonzero" cx="14" cy="14" r="7"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
