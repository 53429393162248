import React from 'react';
import { Items } from 'types';
import { LayerItem } from './LayerItem';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';

interface LayerListProps {
  layers: Items[];
  selected: number | null;
  handleSelect: (id: number) => void;
  handleNameChange: (id: number, newName: string) => void;
  handleToggleVisibility: (id: number) => void;
  handleDeleteLayer: (id: number) => void;
  handleOrderChange: (oldIndex: number, newIndex: number) => void
}

export const LayerList = (props: LayerListProps) => {
  const { layers, selected, handleSelect, handleNameChange, handleToggleVisibility, handleDeleteLayer, handleOrderChange} = props;
  const handleDragEnd = (result: DropResult) => {
    if (result.destination?.index === undefined){
      return
    }
    handleOrderChange(result.source.index, result.destination.index)
  }
  return (
    <div>
      <DragDropContext
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {layers.map((layer, idx) => (
                <LayerItem
                  key={`layer-${layer.id}`}
                  layer={layer}
                  order={idx}
                  selected={selected === layer.id}
                  handleSelect={handleSelect}
                  handleNameChange={handleNameChange}
                  handleVisibilityToggle={handleToggleVisibility}
                  handleDeleteLayer={handleDeleteLayer}
                />
              ))}
            {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
