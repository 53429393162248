import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { IconButton, useStyles2 } from '@grafana/ui';
import { Items } from 'types';
import { LayerName } from './LayerName';
import { Draggable } from '@hello-pangea/dnd';

interface LayerItemProps {
  layer: Items;
  selected: boolean;
  order: number;
  handleSelect: (id: number) => void;
  handleNameChange: (id: number, newName: string) => void;
  handleVisibilityToggle: (id: number) => void;
  handleDeleteLayer: (id: number) => void;
}

export const LayerItem = (props: LayerItemProps) => {
  const style = useStyles2(getStyles);
  const getRowStyle = (isSelected: boolean) => {
    return isSelected ? `${style.row} ${style.sel}` : style.row;
  };
  const { layer, selected, handleSelect, handleNameChange, handleVisibilityToggle, handleDeleteLayer, order } = props;
  const handleItemNameChange = handleNameChange.bind(null, layer.id);
  return (
    <Draggable key={layer.id} draggableId={`${layer.id}`} index={order}>
      {(provided, snapshot) => (
        <div
          className={getRowStyle(selected)}
          onMouseDown={() => handleSelect(layer.id)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          role="button"
          tabIndex={0}
        >
          <LayerName name={layer.name} onChange={handleItemNameChange} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <IconButton
              name={layer.visible ? 'eye' : 'eye-slash'}
              tooltip={'toggle visibility'}
              className={style.actionIcon}
              onClick={() => handleVisibilityToggle(layer.id)}
            />
            <IconButton
              name="trash-alt"
              tooltip={'delete layer'}
              className={style.actionIcon}
              onClick={() => handleDeleteLayer(layer.id)}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    wrapper: css({
      marginBottom: theme.spacing(2),
    }),
    row: css({
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.shape.radius.default,
      background: theme.colors.background.secondary,
      minHeight: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '3px',
      cursor: 'pointer',

      border: `1px solid ${theme.components.input.borderColor}`,
      '&:hover': {
        border: `1px solid ${theme.components.input.borderHover}`,
      },
    }),
    sel: css({
      border: `1px solid ${theme.colors.primary.border}`,
      '&:hover': {
        border: `1px solid ${theme.colors.primary.border}`,
      },
    }),
    dragIcon: css({
      cursor: 'drag',
    }),
    actionIcon: css({
      color: theme.colors.text.secondary,
      '&:hover': {
        color: theme.colors.text.primary,
      },
    }),
    typeWrapper: css({
      color: theme.colors.primary.text,
      marginRight: '5px',
    }),
    textWrapper: css({
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      overflow: 'hidden',
      marginRight: theme.spacing(1),
    }),
  };
}
