import React from 'react';
import { Button } from '@grafana/ui';
import { StandardEditorProps } from '@grafana/data';
import { Graph, WindDirection } from 'types';

export const AddGraphButton = (props: StandardEditorProps<Graph[]>) => {
  const {value, onChange} = props
  return <Button name='Add Graph' onClick={() => {
    let id = 0
    for (const layer of value) {
      if (id <= layer.id){
        id = layer.id + 1
      }
    }

    const newLayer: Graph = {
      id,
      name: `graph-${id}`,
      visible: true,
      plotInfo: {plotCategory: 'wind-direction'} as WindDirection
    }
    onChange([...value, newLayer])
  }}>Add Graph</Button>;
}
