import React from 'react';
import { ColorHue, ColorScale, ColorSpace, FixedColor, FixedSize, MapLayer, OpacityScale, SizeScale } from 'types';
import { DataSelector } from './DataSelector';
import { StandardEditorContext } from '@grafana/data';
import { ColorPicker, Label, Slider, Checkbox } from '@grafana/ui';
import { RangeSlider } from './RangeSlider';
import { VectorShapeSelector } from './VectorShapeSelector';
import { VectorShape } from 'utils/createMarker';
import { ColorSchemeEditor } from './ColorSchemeEditor';
import { ColumnSelector } from './ColumnSelector';
import './MarkerEditor.css';

interface VectorLayerEditorProps {
  layerId: number;
  layers: MapLayer[];
  handleUpdate: (layers: MapLayer[]) => void;
  context: StandardEditorContext<MapLayer[]>;
}

export const VectorEditor = (props: VectorLayerEditorProps) => {
  const { layerId, layers, handleUpdate, context } = props;
  const layer = layers.find((l) => l.id === layerId);
  if (!layer || layer.plotInfo === undefined || layer.plotInfo.layerCategory !== 'vector') {
    return <span>Layer undefined</span>;
  }
  const plotInfo = layer.plotInfo;
  const dataframe = context.data.find((d) => d.refId === plotInfo.dataframe);
  const handleDataframeUpdate = (newDataFrame: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    newLayer.plotInfo.dataframe = newDataFrame;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleUpdateColor = (newColor: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.markerColor.colorCategory !== 'fixed') {
      return;
    }
    newLayer.plotInfo.markerColor.color = newColor;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleUpdateShape = (newShape: VectorShape) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    newLayer.plotInfo.markerType = newShape;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleUpdateLength = (newSize: number) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorLength.sizeCategory !== 'fixed') {
      return;
    }
    newLayer.plotInfo.vectorLength.size = newSize;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleUpdateWidth = (newSize: number) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorWidth.sizeCategory !== 'fixed') {
      return;
    }
    newLayer.plotInfo.vectorWidth.size = newSize;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleColor1Change = (newColor1: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.markerColor.colorCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.markerColor.colorScheme[0] = newColor1;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleColor2Change = (newColor2: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.markerColor.colorCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.markerColor.colorScheme[1] = newColor2;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleColorColumnChange = (newColumn: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.markerColor.colorCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.markerColor.column = newColumn;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleLengthColumnChange = (newColumn: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorLength.sizeCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.vectorLength.column = newColumn;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleWidthColumnChange = (newColumn: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorWidth.sizeCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.vectorWidth.column = newColumn;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleColorCategoryChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.markerColor.colorCategory === 'scale') {
      newLayer.plotInfo.markerColor = { colorCategory: 'fixed', color: '#FF6E6E' } as FixedColor;
    } else {
      newLayer.plotInfo.markerColor = {
        colorCategory: 'scale',
        colorScheme: ['red', 'blue'],
        colorSpace: 'lch',
        colorHue: 'shorter',
        maxValue: null,
        minValue: null,
      } as ColorScale;
    }
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleLengthCategoryChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorLength.sizeCategory === 'scale') {
      newLayer.plotInfo.vectorLength = { sizeCategory: 'fixed', size: 15 } as FixedSize;
    } else {
      newLayer.plotInfo.vectorLength = {
        sizeCategory: 'scale',
        maxValue: 100,
        minValue: 1,
      } as SizeScale;
    }
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleWidthCategoryChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorWidth.sizeCategory === 'scale') {
      newLayer.plotInfo.vectorWidth = { sizeCategory: 'fixed', size: 15 } as FixedSize;
    } else {
      newLayer.plotInfo.vectorWidth = {
        sizeCategory: 'scale',
        maxValue: 100,
        minValue: 1,
      } as SizeScale;
    }
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleLengthRangeChange = (newRange: number | number[]) => {
    if (!Array.isArray(newRange)) {
      return;
    }
    const [min, max] = newRange;
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorLength.sizeCategory !== 'scale') {
      return;
    }

    newLayer.plotInfo.vectorLength.minValue = min;
    newLayer.plotInfo.vectorLength.maxValue = max;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleWidthRangeChange = (newRange: number | number[]) => {
    if (!Array.isArray(newRange)) {
      return;
    }
    const [min, max] = newRange;
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorWidth.sizeCategory !== 'scale') {
      return;
    }

    newLayer.plotInfo.vectorWidth.minValue = min;
    newLayer.plotInfo.vectorWidth.maxValue = max;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleOpacityCategoryChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.opacityScale !== undefined) {
      newLayer.plotInfo.opacityScale = undefined;
    } else {
      newLayer.plotInfo.opacityScale = {
        maxOpacity: 1,
        minOpacity: 0.1,
      } as OpacityScale;
    }
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleOpacityColumnChange = (newColumn: string) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.opacityScale === undefined) {
      return;
    }
    newLayer.plotInfo.opacityScale.column = newColumn;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleOpacityRangeChange = (newRange: number | number[]) => {
    if (!Array.isArray(newRange)) {
      return;
    }
    const [min, max] = newRange;
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.opacityScale === undefined) {
      return;
    }

    newLayer.plotInfo.opacityScale.minOpacity = min;
    newLayer.plotInfo.opacityScale.maxOpacity = max;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleZoomInvariantChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    newLayer.plotInfo.zoomInvariant = !newLayer.plotInfo.zoomInvariant;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleColorSpaceChange = (colorSpace: ColorSpace) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector' || newLayer.plotInfo.markerColor.colorCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.markerColor.colorSpace = colorSpace;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleColorHueChange = (colorHue: ColorHue) => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector' || newLayer.plotInfo.markerColor.colorCategory !== 'scale') {
      return;
    }
    newLayer.plotInfo.markerColor.colorHue = colorHue;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleLengthAutoChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorLength.sizeCategory !== 'scale') {
      return;
    }

    newLayer.plotInfo.vectorLength.auto = !newLayer.plotInfo.vectorLength.auto;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  const handleWidthAutoChange = () => {
    const newLayer: MapLayer = { ...layer };
    if (newLayer.plotInfo?.layerCategory !== 'vector') {
      return;
    }
    if (newLayer.plotInfo.vectorWidth.sizeCategory !== 'scale') {
      return;
    }

    newLayer.plotInfo.vectorWidth.auto = !newLayer.plotInfo.vectorWidth.auto;
    const newLayers = [...layers];
    const i = newLayers.findIndex((l) => l.id === newLayer.id);
    if (i === -1) {
      return;
    }
    newLayers[i] = newLayer;
    handleUpdate(newLayers);
  };

  return (
    <>
      <DataSelector value={layer.plotInfo.dataframe} context={context} handleChange={handleDataframeUpdate} />
      <div className="section-container">
        <h6>Marker Color</h6>
        <Checkbox checked={layer.plotInfo.markerColor.colorCategory === 'scale'} onClick={handleColorCategoryChange} />
        <span className="checkbox-label">Vary marker color with column?</span>
        {layer.plotInfo.markerColor.colorCategory === 'fixed' && (
          <>
            <Label>Marker Color</Label>
            <ColorPicker color={layer.plotInfo.markerColor.color} onChange={handleUpdateColor} />
          </>
        )}
        {layer.plotInfo.markerColor.colorCategory === 'scale' && dataframe !== undefined && (
          <ColorSchemeEditor
            column={layer.plotInfo.markerColor.column}
            dataframe={dataframe}
            color1={layer.plotInfo.markerColor.colorScheme[0]}
            color2={layer.plotInfo.markerColor.colorScheme[1]}
            colorhue={layer.plotInfo.markerColor.colorHue}
            colorspace={layer.plotInfo.markerColor.colorSpace}
            handleColorHueChange={handleColorHueChange}
            handleColorSpaceChange={handleColorSpaceChange}
            handleColor1Change={handleColor1Change}
            handleColor2Change={handleColor2Change}
            handleColumnChange={handleColorColumnChange}
          />
        )}
        <Checkbox checked={layer.plotInfo.opacityScale !== undefined} onClick={handleOpacityCategoryChange} />
        <span className="checkbox-label">Vary marker opacity with column?</span>
        {layer.plotInfo.opacityScale !== undefined && dataframe !== undefined && (
          <>
            <Label>Opacity Column</Label>
            <ColumnSelector
              value={layer.plotInfo.opacityScale.column}
              dataframe={dataframe}
              handleChange={handleOpacityColumnChange}
            />
            <Label>Opacity Range</Label>
            <div className="slider-container">
              <RangeSlider
                value={[layer.plotInfo.opacityScale.minOpacity, layer.plotInfo.opacityScale.maxOpacity]}
                min={0}
                max={1}
                step={0.01}
                onChange={handleOpacityRangeChange}
              />
            </div>
          </>
        )}
      </div>

      <VectorShapeSelector value={layer.plotInfo.markerType} handleChange={handleUpdateShape} />

      <div className="section-container">
        <h6>Vector Length</h6>
        <div className="checkbox-container">
          <Checkbox
            checked={layer.plotInfo.vectorLength.sizeCategory === 'scale'}
            onClick={handleLengthCategoryChange}
          />
          <span className="checkbox-label">Vary vector length with column?</span>
        </div>

        {layer.plotInfo.vectorLength.sizeCategory === 'fixed' && (
          <Slider
            key={`slider${layer.id}`}
            min={0}
            max={100}
            value={layer.plotInfo.vectorLength.size}
            onChange={handleUpdateLength}
          />
        )}
        {layer.plotInfo.vectorLength.sizeCategory === 'scale' && dataframe !== undefined && (
          <>
            <ColumnSelector
              value={layer.plotInfo.vectorLength.column}
              dataframe={dataframe}
              handleChange={handleLengthColumnChange}
            />
            <div className="checkbox-container">
              <Checkbox checked={layer.plotInfo.vectorLength.auto} onClick={handleLengthAutoChange} />
              <span className="checkbox-label">Auto scale?</span>
            </div>

            {!layer.plotInfo.vectorLength.auto &&
              layer.plotInfo.vectorLength.minValue !== undefined &&
              layer.plotInfo.vectorLength.maxValue !== undefined && (
                <>
                  <Label>Length Range</Label>
                  <div className="slider-container">
                    <RangeSlider
                      value={[layer.plotInfo.vectorLength.minValue, layer.plotInfo.vectorLength.maxValue]}
                      step={1}
                      min={0}
                      max={250}
                      onChange={handleLengthRangeChange}
                    />
                  </div>
                </>
              )}
          </>
        )}
      </div>

      <div className="section-container">
        <h6>Vector Width</h6>
        <div className="checkbox-container">
          <Checkbox checked={layer.plotInfo.vectorWidth.sizeCategory === 'scale'} onClick={handleWidthCategoryChange} />
          <span className="checkbox-label">Vary vector width with column?</span>
        </div>

        {layer.plotInfo.vectorWidth.sizeCategory === 'fixed' && (
          <Slider
            key={`slider${layer.id}`}
            min={0}
            max={100}
            value={layer.plotInfo.vectorWidth.size}
            onChange={handleUpdateWidth}
          />
        )}
        {layer.plotInfo.vectorWidth.sizeCategory === 'scale' && dataframe !== undefined && (
          <>
            <ColumnSelector
              value={layer.plotInfo.vectorWidth.column}
              dataframe={dataframe}
              handleChange={handleWidthColumnChange}
            />
            <div className="checkbox-container">
              <Checkbox checked={layer.plotInfo.vectorWidth.auto} onClick={handleWidthAutoChange} />
              <span className="checkbox-label">Auto scale?</span>
            </div>

            {!layer.plotInfo.vectorWidth.auto &&
              layer.plotInfo.vectorWidth.minValue !== undefined &&
              layer.plotInfo.vectorWidth.maxValue !== undefined && (
                <>
                  <Label>Width Range</Label>
                  <div className="slider-container">
                    <RangeSlider
                      value={[layer.plotInfo.vectorWidth.minValue, layer.plotInfo.vectorWidth.maxValue]}
                      step={1}
                      min={0}
                      max={250}
                      onChange={handleWidthRangeChange}
                    />
                  </div>
                </>
              )}
          </>
        )}
        <div className="checkbox-container">
          <Checkbox onChange={handleZoomInvariantChange} checked={plotInfo.zoomInvariant} />
          <span className="checkbox-label">Should the marker size be invariant with zoom?</span>
        </div>
      </div>
    </>
  );
};
