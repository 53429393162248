import React from 'react';
import { Button } from '@grafana/ui';
import { StandardEditorProps } from '@grafana/data';
import { MapLayer } from 'types';

export const AddLayerButton = (props: StandardEditorProps<MapLayer[]>) => {
  const {value, onChange} = props
  return <Button name='Add layer' onClick={() => {
    let id = 0
    for (const layer of value) {
      if (id <= layer.id){
        id = layer.id + 1
      }
    }

    const newLayer: MapLayer = {
      id,
      name: `layer-${id}`,
      visible: true,
      legendVisible: true,
      popup: []
    }
    onChange([...value, newLayer])
  }}>Add Layer</Button>;
}
