import React from "react";
import { MapLayer } from "types";
import { DataSelector } from "./DataSelector";
import { StandardEditorContext } from "@grafana/data";
import { ColorPicker, Label } from "@grafana/ui";

interface LayerEditorProps {
    layerId: number;
    layers: MapLayer[];
    handleUpdate: (layers: MapLayer[]) => void;
    context: StandardEditorContext<MapLayer[]>
}

export const LineEditor = (props: LayerEditorProps) => {
    const { layerId, layers, handleUpdate, context } = props
    const layer = layers.find(l => l.id === layerId)
    if (!layer || layer.plotInfo === undefined || layer.plotInfo.layerCategory !== "line") {
        return <span>Layer undefined</span>
    }
    const handleDataframe1Update = (newDataFrame: string) => {
        const newLayer: MapLayer = { ...layer }
        if (newLayer.plotInfo?.layerCategory !== "line") {
            return
        }
        newLayer.plotInfo.dataframe1 = newDataFrame
        const newLayers = [...layers]
        const i = newLayers.findIndex((l) => l.id === newLayer.id)
        if (i === -1) {
            return
        }
        newLayers[i] = newLayer
        handleUpdate(newLayers)
    }

    const handleDataframe2Update = (newDataFrame: string) => {
        const newLayer: MapLayer = { ...layer }
        if (newLayer.plotInfo?.layerCategory !== "line") {
            return
        }
        newLayer.plotInfo.dataframe2 = newDataFrame
        const newLayers = [...layers]
        const i = newLayers.findIndex((l) => l.id === newLayer.id)
        if (i === -1) {
            return
        }
        newLayers[i] = newLayer
        handleUpdate(newLayers)
    }

    const handleUpdateColor = (newColor: string) => {
        const newLayer: MapLayer = { ...layer }
        if (newLayer.plotInfo?.layerCategory !== "line") {
            return
        }
        newLayer.plotInfo.lineColor = newColor
        const newLayers = [...layers]
        const i = newLayers.findIndex((l) => l.id === newLayer.id)
        if (i === -1) {
            return
        }
        newLayers[i] = newLayer
        handleUpdate(newLayers)
    }

    return <>
        <DataSelector value={layer.plotInfo.dataframe1} context={context} handleChange={handleDataframe1Update} />
        <DataSelector value={layer.plotInfo.dataframe2} context={context} handleChange={handleDataframe2Update} />
        <Label>Marker Color</Label>
        <ColorPicker color={layer.plotInfo.lineColor} onChange={handleUpdateColor} />
    </>
}
