// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-selector {
	display: inline-flex;
	padding: 10px 12px;
	margin: 0;
	cursor: pointer;
	font-size: small;
	width: 75px;
	justify-content: center;
	outline: 0.5px solid gray;
}

.view-selector-container {
	background-color: white;

}

.selector {
	display: none
}

#google-logo {
	border: none
}

.map-control {
	color: black;
}

input:hover + label {
	background-color: #e4e4e4;
}

input:checked + label {
	background-color: #b4b4b4;
}

.leaflet-div-icon {
	background: none;
	border: none
}`, "",{"version":3,"sources":["webpack://./components/SimplePanel.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,kBAAkB;CAClB,SAAS;CACT,eAAe;CACf,gBAAgB;CAChB,WAAW;CACX,uBAAuB;CACvB,yBAAyB;AAC1B;;AAEA;CACC,uBAAuB;;AAExB;;AAEA;CACC;AACD;;AAEA;CACC;AACD;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;CAChB;AACD","sourcesContent":[".view-selector {\n\tdisplay: inline-flex;\n\tpadding: 10px 12px;\n\tmargin: 0;\n\tcursor: pointer;\n\tfont-size: small;\n\twidth: 75px;\n\tjustify-content: center;\n\toutline: 0.5px solid gray;\n}\n\n.view-selector-container {\n\tbackground-color: white;\n\n}\n\n.selector {\n\tdisplay: none\n}\n\n#google-logo {\n\tborder: none\n}\n\n.map-control {\n\tcolor: black;\n}\n\ninput:hover + label {\n\tbackground-color: #e4e4e4;\n}\n\ninput:checked + label {\n\tbackground-color: #b4b4b4;\n}\n\n.leaflet-div-icon {\n\tbackground: none;\n\tborder: none\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
