import React from 'react';
import { Select, Label } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { PlotType, MarkerLayer, LineLayer, VectorLayer, MapLayer, FixedSize, FixedColor } from 'types';
import { Shape, VectorShape } from 'utils/createMarker';

interface DataSelectorProps {
  handleChange: (id: number, selectorValue: PlotType) => void;
  id: number;
  layers: MapLayer[];
}

export const PlotSelector = ({ id, layers, handleChange }: DataSelectorProps) => {
  const layer = layers.find((l) => l.id === id);
  if (layer === undefined) {
    return null;
  }

  const defaultValues: Record<string, PlotType> = {
    marker: {
      layerCategory: 'marker',
      latitudeColumn: 'latitude',
      longitudeColumn: 'longitude',
      markerColor: { colorCategory: 'fixed', color: '#FF6E6E' } as FixedColor,
      markerSize: { sizeCategory: 'fixed', size: 15 } as FixedSize,
      markerType: Shape.Marker,
      zoomInvariant: false,
    } as MarkerLayer,
    line: {
      layerCategory: 'line',
      latitude1Column: 'latitude',
      longitude1Column: 'longitude',
      latitude2Column: 'latitude',
      longitude2Column: 'longitude',
      lineColor: 'black',
      lineThickness: 2,
    } as LineLayer,
    vector: {
      layerCategory: 'vector',
      latitudeColumn: 'latitude',
      longitudeColumn: 'longitude',
      rotationColumn: 'rotation',
      markerColor: { colorCategory: 'fixed', color: '#FF6E6E' } as FixedColor,
      markerType: VectorShape.Arrow,
      vectorLength: { sizeCategory: 'fixed', size: 15 } as FixedSize,
      vectorWidth: { sizeCategory: 'fixed', size: 15 } as FixedSize,
      zoomInvariant: false,
    } as VectorLayer,
  };

  const options: Array<SelectableValue<string>> = [
    {
      label: 'Marker',
      value: 'marker',
    },
    {
      label: 'Line',
      value: 'line',
    },
    {
      label: 'Vector',
      value: 'vector',
    },
  ];

  return (
    <>
      <Label>Plot Type</Label>
      <Select
        key={layer.id}
        options={options}
        value={layer.plotInfo?.layerCategory}
        onChange={(selectableValue) => {
          if (selectableValue.value !== undefined) {
            handleChange(id, defaultValues[selectableValue.value]);
          }
        }}
      />
    </>
  );
};
