import React from 'react';

export interface PolarGraphProps {
  angle: number;
  windspeed: number;
  turbulence: number;
}

export function WindDirectionPanel({ angle, windspeed, turbulence }: PolarGraphProps) {
  let length = windspeed * 10;
  if (length > 70) {
    length = 70;
  } else if (length < 30) {
    length = 30;
  }
  let width = turbulence * 5;
  if (width > 70) {
    width = 70;
  } else if (width < 30) {
    width = 30;
  }

  return (
    <div>
      <svg
        width={width}
        style={{ transform: `rotate(${angle}deg)` }}
        viewBox={`0 0 ${width} ${length + 3}`}
        fill="blue"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={`M${width - 3},${length + 3}L${width / 2},${(length + 3) / 1.5}L3,${length + 3}L${width / 2},3Z`} />
      </svg>
    </div>
  );
}
