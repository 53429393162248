import { StandardEditorContext } from '@grafana/data';
import React from 'react';
import { Graph } from 'types';
import { ColumnSelector } from './ColumnSelector';
import { DataSelector } from './DataSelector';
import { Label } from '@grafana/ui';

export interface WindDirectionEditorProps {
  graphId: number;
  graphs: Graph[];
  context: StandardEditorContext<Graph>;
  handleUpdate: (newGraphs: Graph[]) => void;
}

export function WindDirectionEditor({ graphId, graphs, context, handleUpdate }: WindDirectionEditorProps) {
  const graph = graphs.find((g) => g.id === graphId);
  if (!graph || graph.plotInfo === undefined || graph.plotInfo.plotCategory !== 'wind-direction') {
    return <span>Layer undefined</span>;
  }

  const plotInfo = graph.plotInfo;
  const dataframe = context.data.find((d) => d.refId === plotInfo.dataframe);

  const handleDataframeUpdate = (newDataFrame: string) => {
    const newGraph: Graph = { ...graph };
    if (newGraph.plotInfo?.plotCategory !== 'wind-direction') {
      return;
    }
    newGraph.plotInfo.dataframe = newDataFrame;
    const newGraphs = [...graphs];
    const i = newGraphs.findIndex((g) => g.id === newGraph.id);
    if (i === -1) {
      return;
    }
    newGraphs[i] = newGraph;
    handleUpdate(newGraphs);
  };
  const handleWindDirectionColumnChange = (newColumn: string) => {
    const newGraph: Graph = { ...graph };
    if (newGraph.plotInfo?.plotCategory !== 'wind-direction') {
      return;
    }
    newGraph.plotInfo.windDirectionColumn = newColumn;
    const newGraphs = [...graphs];
    const i = newGraphs.findIndex((g) => g.id === newGraph.id);
    if (i === -1) {
      return;
    }
    newGraphs[i] = newGraph;
    handleUpdate(newGraphs);
  };

  const handleWindSpeedColumnChange = (newColumn: string) => {
    const newGraph: Graph = { ...graph };
    if (newGraph.plotInfo?.plotCategory !== 'wind-direction') {
      return;
    }
    newGraph.plotInfo.windSpeedColumn = newColumn;
    const newGraphs = [...graphs];
    const i = newGraphs.findIndex((g) => g.id === newGraph.id);
    if (i === -1) {
      return;
    }
    newGraphs[i] = newGraph;
    handleUpdate(newGraphs);
  };

  const handleTurbulenceColumnChange = (newColumn: string) => {
    const newGraph: Graph = { ...graph };
    if (newGraph.plotInfo?.plotCategory !== 'wind-direction') {
      return;
    }
    newGraph.plotInfo.turbulenceColumn = newColumn;
    const newGraphs = [...graphs];
    const i = newGraphs.findIndex((g) => g.id === newGraph.id);
    if (i === -1) {
      return;
    }
    newGraphs[i] = newGraph;
    handleUpdate(newGraphs);
  };

  return (
    <div>
      <DataSelector value={graph.plotInfo?.dataframe} context={context} handleChange={handleDataframeUpdate} />

      {dataframe && (
        <>
          <Label>Wind Direction Column</Label>
          <ColumnSelector
            value={graph.plotInfo?.windDirectionColumn}
            dataframe={dataframe}
            handleChange={handleWindDirectionColumnChange}
          />
        </>
      )}
      {dataframe && (
        <>
          <Label>Wind Speed Column</Label>
          <ColumnSelector
            value={graph.plotInfo?.windSpeedColumn}
            dataframe={dataframe}
            handleChange={handleWindSpeedColumnChange}
          />
        </>
      )}
      {dataframe && (
        <>
          <Label>Turbulence Column</Label>
          <ColumnSelector
            value={graph.plotInfo?.turbulenceColumn}
            dataframe={dataframe}
            handleChange={handleTurbulenceColumnChange}
          />
        </>
      )}
    </div>
  );
}
