import React, { useState } from 'react';
import { MapLayer } from 'types';
import { Arrow, Circle, Ellipse, Marker, Square, Triangle } from './SVGs';
import { Shape, VectorShape } from 'utils/createMarker';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import Color from 'colorjs.io';

export interface LegendItemProps {
  layer: MapLayer;
  visible: boolean;
  setVisibility: (id: number) => void;
}
export function LegendItem({ layer, setVisibility, visible }: LegendItemProps) {
  const [hover, setHover] = useState(false);
  if (!layer.legendVisible) {
    return null;
  }
  let Symbol;
  if (layer.plotInfo === undefined) {
    return null;
  }
  if (layer.plotInfo.layerCategory === 'marker') {
    switch (layer.plotInfo.markerType) {
      case Shape.Circle:
        Symbol = Circle;
        break;
      case Shape.Square:
        Symbol = Square;
        break;
      case Shape.Marker:
        Symbol = Marker;
        break;
      case Shape.Triangle:
        Symbol = Triangle;
        break;
    }
  } else if (layer.plotInfo.layerCategory === 'vector') {
    switch (layer.plotInfo.markerType) {
      case VectorShape.Arrow:
        Symbol = Arrow;
        break;
      case VectorShape.Ellipse:
        Symbol = Ellipse;
        break;
      case VectorShape.Rectangle:
        Symbol = Square;
        break;
    }
  }
  let Icon;
  if (layer.plotInfo.layerCategory === 'marker' || layer.plotInfo.layerCategory === 'vector') {
    if (Symbol === undefined) {
      return null;
    }
    if (layer.plotInfo.markerColor.colorCategory === 'fixed') {
      Icon = <Symbol color={layer.plotInfo.markerColor.color} leftOffset={0} size={13} />;
    } else {
      const [colorString1, colorString2] = layer.plotInfo.markerColor.colorScheme;
      const c1 = new Color(colorString1);
      const c2 = new Color(colorString2);
      const colorScheme = c1.range(c2, {
        space: layer.plotInfo.markerColor.colorSpace,
        hue: layer.plotInfo.markerColor.colorHue,
      });
      const spectrum = [];
      const symbolCount = 5;
      for (let i = 0; i < symbolCount; i++) {
        spectrum.push(
          <Symbol
            key={`${layer.id}-${i}`}
            color={colorScheme(i / (symbolCount - 1)).toString()}
            size={13}
            leftOffset={i * 5}
          />
        );
      }
      Icon = spectrum;
    }
  } else if (layer.plotInfo.layerCategory === 'line') {
    Icon = <div style={{ backgroundColor: layer.plotInfo.lineColor, width: 20, height: 2 }}></div>;
  }
  return (
    <div
      style={{
        fontSize: 13,
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 15px',
        alignContent: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>{Icon}</div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 4 }}>{layer.name}</span>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: hover ? '#D3D3D3' : 'white',
            cursor: hover ? 'pointer' : 'default',
          }}
          onMouseDown={() => setVisibility(layer.id)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {visible ? <IoEyeOutline color="grey" /> : <IoEyeOffOutline color="grey" />}
        </div>
      </div>
    </div>
  );
}
