import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';
import { LayersEditor } from 'components/LayersEditor';
import { GraphsEditor } from 'components/GraphsEditor';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder

    .addCustomEditor({
      id: 'layers',
      path: 'layers',
      name: 'Layers',
      defaultValue: [],
      editor: LayersEditor,
    })
    .addCustomEditor({
      id: 'graphs',
      path: 'graphs',
      name: 'Graphs',
      defaultValue: [],
      editor: GraphsEditor,
    });
});
