// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    padding-top: 5px;
    padding-right: 20px;
}

.checkbox-label {
    margin-left: 5px
}`, "",{"version":3,"sources":["webpack://./components/MarkerEditor.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;AACJ","sourcesContent":[".slider-container {\n    padding-top: 5px;\n    padding-right: 20px;\n}\n\n.checkbox-label {\n    margin-left: 5px\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
