import React from 'react';
import { MapLayer } from 'types';
import { LegendItem } from './LegendItem';


export interface LegendProps {
  layers: MapLayer[];
  setVisibility: (id: number) => void
  layerVisibility: Record<number, boolean>
}

export function Legend({ layers, setVisibility, layerVisibility }: LegendProps) {
  return (
    <div className="leaflet-bottom leaflet-right" style={{ marginBottom: 10 }}>
      <div
        className="leaflet-control leaflet-bar map-control"
        style={{ backgroundColor: 'white', width: 154, maxHeight: 300, overflow: 'auto' }}
      >
        {layers.map((layer) => {
          return <LegendItem key={layer.id} visible={layerVisibility[layer.id]} layer={layer} setVisibility={setVisibility}/>;
        })}
      </div>
    </div>
  );
}
