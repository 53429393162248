import React from 'react';
import { Graph } from 'types';
import { WindDirectionPanel } from './WindDirectionPanel';
import { PanelData } from '@grafana/data';

export interface GraphPanelProps {
  graphs: Graph[];
  data: PanelData;
}

export function GraphPanel({ graphs, data }: GraphPanelProps) {
  if (graphs.length <= 0) {
    return null;
  }
  const allGraphs: React.JSX.Element[] = [];

  const createWindDirectionGraph = () => {
    for (const graph of graphs){
        if (graph.plotInfo === undefined || graph.plotInfo.plotCategory !== 'wind-direction' || !graph.visible) {
            continue;
          }
          const plotInfo = graph.plotInfo
          const series = data.series.find((s) => s.refId === plotInfo.dataframe);
          const windDirection = series?.fields.find((f) => f.name === plotInfo.windDirectionColumn);
          const windSpeed = series?.fields.find((f) => f.name === plotInfo.windSpeedColumn);
          const turbulence = series?.fields.find((f) => f.name === plotInfo.turbulenceColumn);
      
          if (windDirection === undefined || windSpeed === undefined || turbulence === undefined) {
            continue;
          }
          const windDirectionValues = windDirection.values as number[];
          const windSpeedValues = windSpeed.values as number[];
          const turbulenceValues = turbulence.values as number[];
          if (windDirectionValues.length <= 0 || windSpeedValues.length <= 0 || turbulenceValues.length <= 0) {
            continue;
          }
          allGraphs.push(
            <WindDirectionPanel key={graph.id} windspeed={windSpeedValues[0]} angle={windDirectionValues[0]} turbulence={turbulenceValues[0]} />
          );
    }
   
  };

  createWindDirectionGraph()

  return (
    <>
      {allGraphs.length > 0 && (
        <div className="leaflet-top leaflet-left" style={{ marginLeft: 70 }}>
          <div
            className="leaflet-control leaflet-bar map-control"
            style={{ border: 'none', width: 154, maxHeight: '70%', overflow: 'hidden' }}
          >
            {allGraphs}
          </div>
        </div>
      )}
    </>
  );
}
